'use strict'

angular
	.module('cb.services', [])
	.filter('startAtPage', function () {
		return function (input, page, limit) {
			if (input == undefined)
				return null;
			var first = (page - 1) * limit;
			return input.slice(first, first + limit);
		};
	})
	.filter('searched', function () {
		return function (data, searchString) {
			if (!searchString) {
				return null;
			}
			var results = [];
			angular.forEach(data, function (item) {
				var name = item.FirstName + item.LastName + item.Email + item.MobileNumber + (item.Department != null ? item.Department.PickListEntry.replace(" ", "") : "");
				var testString = searchString.replace(" ", "");
				if (name.toLowerCase().indexOf(testString.toLowerCase()) !== -1) {
					results.push(item);
				}
			})
			return results;
		};
	})
	.filter('PicklistSearched', function () {
		return function (data, searchString) {
			if (!searchString) {
				return data;
			}
			var results = [];
			angular.forEach(data, function (item) {
				var testString = searchString.replace(" ", "");
				if (item.toLowerCase().indexOf(testString.toLowerCase()) !== -1) {
					results.push(item);
				}
			})
			return results;
		};
	})
	.filter('PicklistItemSearched', function () {
		return function (data, searchString) {
			if (!searchString) {
				return data;
			}
			var results = [];
			angular.forEach(data, function (item) {
				var testString = searchString.replace(" ", "");
				if (item.PickListEntry.toLowerCase().indexOf(testString.toLowerCase()) !== -1) {
					results.push(item);
				}
			})
			return results;
		};
	})
	.filter('RecipientsList', function () {
		return function (data) {
			var preOutput = [];
			angular.forEach(data, function (item) {
				if (item != null) {
					var s = item.Name + " "
				}
				preOutput.push(s)
			})
			var output = preOutput.toString()
			return output;
		};
	})

	.filter('percentage', ["$filter", function ($filter) {
		return function (input, decimals) {
			if (isNaN(input))
				return '';
			return $filter('number')(input * 100, decimals) + '%';
		};
	}])

	.filter('picklistFilter2', function () {
		return function (input, filter, id, parent) {

			if (filter == 'ddmFabric, System')
				return null;

			if (id == undefined)
				return null;

			var parentString;

			for (var i = 0; i < input.length; i++) {
				if (input[i].Id == id) {
					parentString = input[i].PickListEntry;
				}
			}

			return input.filter(pl => pl.ParentEntry == parentString && (pl.IsDeleted == false || parseInt(pl.Id) == parseInt(id)));

			return returnArray;
		};
	})
	.filter('picklistFilter', function () {
		return function (input, filter, id, parent) {

			if (input == undefined)
				return null;

			var returnArray = [], filterArray = [], parentString;

			if (parent) {
				for (var i = 0; i < input.length; i++) {
					if (input[i].Id == parent ) {
						parentString = input[i].PickListEntry;
					}
				}
				returnArray = input.filter(pl => pl.ParentEntry == parentString && (pl.IsDeleted == false || parseInt(pl.Id) == parseInt(id)));
			} else {
				if (filter.indexOf(',') >= 0)
					filterArray = filter.split(',');
				else
					filterArray.push(filter);

				filterArray.forEach((item) => {
					item = item.trim();
					for (var i = 0; i < input.length; i++) {
						if (input[i].PickListName.toLowerCase() == item.toLowerCase()) {
							var addToPickList = false;
							if (input[i].IsDeleted) {								
								if (parseInt(input[i].Id) == parseInt(id)) {
									// "Deleted" but selected/saved on existing form, add to picklist
									addToPickList = true;
								}
							}
							else {// "Not Deleted", add to picklist
								addToPickList = true;
							}

							if (addToPickList)
								returnArray.push(input[i]);
						}
					}
				})
			}

			return returnArray;
		};

	})
	.filter('quotesFilter', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;

			return input.filter(action => action.Option == filter);
		}
	})
	.filter('quotesFilter2', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;

			return input.filter(action => action.LineId > 0);
		}
	})
	.filter('quotePricingFilter', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;

			return input.filter(action => action.QuoteOption == filter);
		}
	})
	.filter('ActionListFilter', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;
			return input.filter(action => action.Filter.toLowerCase() == filter.toLowerCase());
		};

	})
	.filter('ChildpicklistFilter', function () {
		return function (input, filter, Parent) {
			if (input == undefined || Parent == undefined)
				return null;
			var returnArray = [];
			var a = input.find(a => a.Id == Parent && a.ParentEntry != undefined);
			input = input.filter(x => x.ParentEntry != undefined && x.PickListName.toLowerCase() == filter.toLowerCase())
			for (var i = 0; i < input.length; i++) {

				if (input[i].ParentEntry == Parent) {
					returnArray.push(input[i]);
				}
			}
			return returnArray;
		};

	})
	.filter('picklistSingleFilter', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;
			for (var i = 0; i < input.length; i++) {
				if (input[i].PickListName == filter)
					return input[i].Id;
			}
			return null
		};

	})
	.filter('stafflistRoleFilter', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;
			var returnArray = [];
			for (var i = 0; i < input.length; i++) {
				if (input[i].Roles) {
					for (var f = 0; f < input[i].Roles.length; f++) {
						for (var j = 0; j < filter.length; j++)
							if ((input[i].Roles[f].Name.toLowerCase()) == filter[j].toString().toLowerCase()) {
								returnArray.push(input[i]);
								break;
							}
					}
				}
			}
			return returnArray;
		};

	})
	.filter('nameIdFilter', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;
			var returnArray = [];
			for (var i = 0; i < input.length; i++) {
				if (input[i].Filter == filter)
					returnArray.push(input[i]);
			}
			return returnArray;
		};

	})
	.filter('titlebyid', function () {
		return function (input, filter) {
			if (input == null || filter == null)
				return null;

			var Title = filter.find(v => input == v.Id)
			if (Title)
				return Title.PickListEntry;
		}
	})
	.filter('leadStatusFilter', function () {
		return function (input, filter) {
			if (input == undefined)
				return null;
			var returnArray = [];
			for (var i = 0; i < input.length; i++) {
				if (filter.toLowerCase() == 'all') {
					if (input[i].IsSaleStatus == 0 && input[i].IsEnquiryStatus == 0 && input[i].Name != 'Prospect' && input[i].Name != 'Enquiry')
						returnArray.push(input[i]);
				} else if (filter.toLowerCase() == 'sales') {
					if (input[i].IsSaleStatus == 1)
						returnArray.push(input[i]);
				} else {
					if (input[i].IsEnquiryStatus == 1)
						returnArray.push(input[i]);
				}

			}
			return returnArray;
		};

	})
	.filter('LogMessage', function () {
		return function (input, filter) {
			if (input == undefined) {
				return null;
			}
			if (input.NewValue == null && input.OldValue == null) {
				return input.ActivityDescription;
			} else if (input.NewValue != null && input.OldValue == null) {
				return (input.Field + ' has been set to ' + input.NewValue);
			} else if (input.NewValue == null && input.OldValue != null) {
				return (input.Field + ' has been changed from ' + input.OldValue + ' To Null');
			} else {
				return (input.Field + ' has been changed from ' + input.OldValue + ' To ' + input.NewValue);
			}
		}
	})
	.filter('cbDate', ["$filter", function ($filter) {
		return function (input, format, timezone) {
			if (input == "0001-01-01T00:00:00" || input == "0001-01-01T00:00:00+00:00" || input == 'empty') {
				return 'empty';
			} else {
				if (format == 'mediumDate')
					format = 'dd/MM/yyyy';
				else if (format == 'mediumDateTime')
					format = 'dd/MM/yyyy - HH:mm'
				return $filter('date')(input, format, timezone);
			}
		};
	}])
	.filter('fileType', () => {
		return function (input, filter) {
			switch (input) {
				case '.xlsx':
				case '.xls':
				case '.csv':
					return 'Excel';
				case '.docx':
				case '.doc':
				case '.dot':
					return 'Word Document';
				case '.mp4':
				case '.m4v':
				case '.avi':
				case '.mpeg':
				case '.mov':
					return 'Video';
				case '.pdf':
					return 'PDF';
				case '.jpeg':
				case '.jpg':
				case '.JPG':
				case '.PNG':
				case '.png':
				case '.GIF':
				case '.gif':
					return 'Photo';
				case '.txt':
				case '.md':
					return 'Text File';
				case '.msg':
					return 'Email';
				case '.dng':
					return 'DNG';
				default:
					return 'Unknown';

			}
		}
	})
	.filter('InstallerTableHeader', function () {
		return function (input) {
			if (input == undefined)
				return null;
			if (input == 'Ref')
				return 'Ref. #';
			return input.replace(/([A-Z])/g, ' $1').trim();
		};
	})
	.filter('ExcludedLeadProviders', function () {
		return function (input, filter) {
			if (!input || !input.length)
				return null;
			return input.filter(lp => filter == 'true' ? lp.Excluded : !lp.Excluded);
		};
	})
	.filter('SpacesBeforeCapitals', function () {
		return function (input) {
			if (!input || !input.length)
				return null;
			return input.replace(/([A-Z])/g, ' $1').trim()
		};
	})
	.directive('formatteddate', ["$filter", function ($filter) {
		return {
			restrict: 'EAC',
			require: 'ngModel',
			link: function (scope, element, attr, controller) {
				controller.$parsers = [];
				controller.$formatters = [];

				var formatModelValue = function (value) {
					if (value && typeof value === 'string' && value.indexOf('0001') >= 0)
						setTimeout(() => { controller.$modelValue = null }, 1);
					else
						return value;
				}

				controller.$formatters.push(formatModelValue);
			}
		}
	}])
